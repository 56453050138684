const url =
  process.env.NODE_ENV === "production"
    ? "wss://reallyfinewine.shuttleapp.rs"
    : "ws://localhost:8000";

export async function connect(handlers) {
  const socketPromise = new Promise((resolve) => {
    const ws = new WebSocket(url + "/chat");
    ws.onopen = () => {
      resolve(ws);
    };

    ws.onmessage = (e) => {
      const serverMessage = JSON.parse(e.data);
      dispatchServerMessage(handlers, serverMessage);
    };
  });

  const socket = await socketPromise;

  const chatConnection = {
    chat: (message) => {
      socket.send(chatMessage(message));
    },
    upgrade: (username, password) => {
      socket.send(upgradeMessage(username, password));
    },
  };

  return chatConnection;
}

function dispatchServerMessage(handlers, message) {
  switch (message.type) {
    case "ShowMessageHistory":
      handlers.onShowMessageHistory(message.message);
      break;
    case "Chat":
      handlers.onChat(message.message);
      break;
    case "NewUserJoined":
      handlers.onNewUserJoined(message.message);
      break;
    case "UserLeft":
      handlers.onUserLeft(message.message);
      break;
    case "ChatError":
      handlers.onChatError(message.message);
      break;
  }
}

function chatMessage(message) {
  return JSON.stringify({
    type: "Chat",
    message: message,
  });
}

function upgradeMessage(username, password) {
  return JSON.stringify({
    type: "Upgrade",
    message: {
      username: username,
      password: password,
    },
  });
}
