import * as api from "./api";
import * as ui from "./ui";
import * as handlers from "./handlers";

const seenUsernames = new Set();

const connectionPromise = api.connect({
  onShowMessageHistory: handlers.onShowMessageHistory,
  onChat: handlers.onChat,
  onNewUserJoined: handlers.onNewUserJoined(seenUsernames),
  onUserLeft: handlers.onUserLeft(seenUsernames),
  onChatError: handlers.onChatError,
});

(async () => {
  const connection = await connectionPromise;
  ui.onChatInputEnter((message) => {
    connection.chat(message);
  });

  ui.onSignInClick((username, password) => {
    connection.upgrade(username, password);
  });
})();
