import * as ui from "./ui";

export function onShowMessageHistory(messages) {
  for (const message of messages) {
    ui.newMessage(message.username, message.message);
  }

  ui.scrollToBottomOfMessages();
}

export function onChat({ username, message }) {
  ui.newMessage(username, message);
  if (ui.numMessages() > 200) {
    ui.removeMessageFromTop();
  }

  ui.scrollToBottomOfMessages();
}

export function onNewUserJoined(seenUsernames) {
  return function (username) {
    ui.newUserJoinedMessage(username);
    seenUsernames.add(username);
  };
}

export function onUserLeft(seenUsernames) {
  return function (username) {
    alert(`${username} has left the chat!`);
    seenUsernames.delete(username);
  };
}

export function onChatError(message) {
  alert(message);
}
