const messages = document.querySelector(".messages");
const usernameInput = document.querySelector(".signin__input[type=text]");
const passwordInput = document.querySelector(".signin__input[type=password]");
const signInButton = document.querySelector(".signin__input--button");
const chatInput = document.querySelector(".chat__input");
const chatCompletions = document.querySelector(".chat__completions");

function messagesItem() {
  const item = document.createElement("li");
  item.className = "messages__item";
  return item;
}

export function scrollToBottomOfMessages() {
  messages.scrollTop = messages.scrollHeight;
}

export function newMessage(author, contents) {
  const item = messagesItem();

  const authorNode = document.createTextNode(author);
  const contentsNode = document.createTextNode(contents);

  const authorElement = document.createElement("span");
  authorElement.className = "messages__item__author";
  authorElement.appendChild(authorNode);

  const contentsElement = document.createElement("p");
  contentsElement.className = "messages__item__contents";
  contentsElement.appendChild(contentsNode);

  item.appendChild(authorElement);
  item.appendChild(contentsElement);

  messages.appendChild(item);
}

export function removeMessageFromTop() {
  messages.removeChild(messages.firstChild);
}

export function numMessages() {
  return messages.childElementCount;
}

export function newUserJoinedMessage(username) {
  const item = messagesItem();
  const textNode = document.createTextNode(`${username} joined`);

  const p = document.createElement("p");
  p.className = "messages__item__contents messages__item__contents--user-join";
  p.appendChild(textNode);

  item.appendChild(p);

  messages.appendChild(item);
}

export function onChatInputEnter(callback) {
  chatInput.addEventListener("keydown", (e) => {
    if (e.key === "Enter") {
      callback(chatInput.value);
      chatInput.value = "";
    }
  });
}

export function onSignInClick(callback) {
  signInButton.addEventListener("click", (e) => {
    e.preventDefault();

    const username = usernameInput.value;
    const password = passwordInput.value;
    callback(username, password);
  });
}

export function addChatCompletions(completions) {
  for (const completion of completions) {
    const completionElement = document.createElement("li");
    completionElement.className = "chat__completion";
    completionElement.textContent = completion;

    chatCompletions.appendChild(completionElement);
  }
}
